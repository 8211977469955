import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';
export const LoginWrap = styled.div`
  max-width: 49%;
  text-align: center;
  padding: 4.8rem;
  margin: 0 1.2rem;
  border-radius: 0.4rem;

  ${({ theme: { LoginStyle } }) =>
    LoginStyle &&
    css`
      box-shadow: 0 0.3rem 0.6rem ${LoginStyle.BdrColors.LoginWrap};
      background-color: ${LoginStyle.BgColors.bg};
    `}
  ${device.mobileLg} {
    max-width: 40rem;
    padding: 1.5rem;
    margin: 1.7rem 0 0;
  }
  ${device.mobileTabSm} {
    max-width: 64%;
  }
`;
export const PageHeading = styled.h1`
  ${Mixin.AllCenter()}
  margin-bottom: 1.6rem;
`;
export const HeadingIcon = styled.img`
  margin-right: 1.2rem;
`;
export const Forgetlink = styled.div`
  text-align: left;
  margin-bottom: 1.6rem;
`;
export const PageDesc = styled.p`
  margin-bottom: 1.6rem;
`;

export const BtnWrap = styled.div<{ isPartnerPage?: boolean }>`
  margin: 0 auto 1.6rem;
  text-align: center;
  width: ${({ isPartnerPage }) => (isPartnerPage ? '50%' : '100%')};
  min-width: 16rem;
  ${device.mobileLg} {
    margin: 2.5rem auto 1.6rem;
  }
`;
export const PageWrap = styled.div<{ isForgotPassword: boolean }>`
  background: right 0
    url(${process.env.NEXT_PUBLIC_CONTENT_URL}/images/bg-login.jpg) no-repeat;
  background-size: cover;
  padding-bottom: ${({ isForgotPassword }) =>
    isForgotPassword ? '4.5rem' : '9.5rem'};
  ${device.mobileLg} {
    padding-bottom: 2.3rem;
  }
  ${device.mobileSm} {
    background: none;
  }
`;

export const WelcomeWrap = styled.h2`
  scroll-margin-top: 14rem;
  margin-bottom: 2.4rem;
`;

export const JoinNow = styled.p`
  margin-bottom: 0;
`;
export const LoginFormWrap = styled.div`
  margin-top: 1.6rem;
  label {
    margin-bottom: 0;
    font-weight: 500;
  }
`;

export const BreadCrumbWrap = styled.div`
  width: 100%;
`;

export const TopOptions = styled.div`
  padding: 0;
`;

export const OtherOptWrap = styled.div<{
  isLogin?: boolean;
  mSignUp?: boolean;
}>`
  margin-top: 0.3rem;
  text-align: center;
  position: relative;
  margin: ${({ isLogin }) => (isLogin ? '0 1.5rem' : '')};
  ${({ theme: { LoginStyle }, mSignUp }) =>
    LoginStyle &&
    css`
      color: #343a40;
      &:before,
      &:after {
        content: '';
        width: ${mSignUp ? '42%' : '45%'};
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        border-top: 0.1rem solid ${LoginStyle.BdrColors.OtherOptWrap};
      }
      ${device.mobileLg} {
        &::before,
        &::after {
          width: 28%;
        }
      }
    `}
  &:after {
    position: absolute;
    right: 0;
    left: auto;
  }
`;

export const InfoBanner = styled.div`
  margin: 1.6rem;
  padding: 1.6rem;
  border-radius: 0.4rem;
  text-align: center;
  ${device.mobileLg} {
    margin: 1.6rem 0;
  }
  ${({ theme: { LoginStyle } }) =>
    LoginStyle &&
    css`
      color: ${LoginStyle.Colors.OtherOptWrap};
      background: ${LoginStyle.BgColors.popupbg};
      border: 0.1rem solid ${LoginStyle.BdrColors.popupbdr};
    `}
`;

export const LoginFormGroupWrap = styled.div`
  margin-bottom: 1.6rem;
  text-align: left;

  label {
    color: rgb(33, 37, 41);
  }
`;

export const LoginInputField = styled.input`
  border: 0.1rem solid rgb(204, 204, 204);
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;
  outline: 0px;
`;

export const LegalText = styled.div`
  font-size: 1.4rem;
  line-height: 1.2;
  font-weight: 400;
  margin-top: 2.4rem;
  margin-bottom: 2.5rem;
  text-align: center;
  a {
    font-weight: 500;
  }
  ${device.mobileLg} {
    font-size: 1.2rem;
    max-width: 20.7rem;
    margin: 2.4rem auto 2.2rem;
    a {
      font-weight: 600;
    }
  }
`;
