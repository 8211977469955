import { Category, SubCategories } from '@components/shared.types';
import { useOutsideClick } from 'helper/hooks/useOutsideClick';
import { useEffect, useRef, useState } from 'react';
import { basePathRewrite, dynamicValueAssign } from '../index';
import { BREAD_CRUMBS } from './constant';
import {
  BreadCrumbList,
  BreadCrumbWrap,
  CategoriesDropdown,
  CategoryList,
  CategoryWrap,
} from './styled';

type Props = {
  page: string;
  dynamicValues?: string[];
  categoryDropdown?: SubCategories;
  skipSubCategoryTitle?: boolean;
  categoryTitle?: any;
  moreDropdownText?: string;
  showCategoryDropdown?: boolean;
};

export type BreadCrumbType = {
  page: string;
  title: string;
  route: string;
  children: {
    name: string;
    path: string;
    static: boolean;
  }[];
}[];

const Breadcrumb = (props: Props) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const { page } = props;

  let BREAD_CRUMB_CONST = BREAD_CRUMBS as BreadCrumbType;
  const breadCrumbPath = BREAD_CRUMB_CONST.find((item) => item.page === page);

  const getDynamicBreadCrumbName = (name: string): string => {
    return dynamicValueAssign(name, props.dynamicValues);
  };

  const dialogContentRef = useRef<HTMLDivElement | null>(null);

  const isOutsideClick = useOutsideClick(dialogContentRef);

  useEffect(() => {
    if (isOutsideClick) {
      setDropdownActive(false);
    }
  }, [isOutsideClick, props]);

  const handleCategory = (e: any) => {
    e.preventDefault();
    setDropdownActive(!dropdownActive);
  };

  const getCategoryDropdown = (category: Category[]) => {
    return (
      <>
        {category.map((cat, index) => (
          <li key={'cat' + index}>
            <a
              href={`${cat.name === 'See More Jobs' ? '/' : '/remote-jobs/'}${
                cat.slug.startsWith('/')
                  ? cat.slug.substring(1).toLowerCase()
                  : cat.slug.toLowerCase()
              }`}
            >
              {cat.name}
            </a>
          </li>
        ))}
      </>
    );
  };

  return (
    <BreadCrumbWrap>
      {breadCrumbPath?.children.map((crumb, index) => {
        return !crumb.static ? (
          <BreadCrumbList
            key={index}
            id={getDynamicBreadCrumbName(crumb.name)
              .trim()
              ?.replace(/\s+(?!\s*$)/g, '-')}
          >
            <a href={basePathRewrite(getDynamicBreadCrumbName(crumb.path))}>
              {getDynamicBreadCrumbName(crumb.name)}
            </a>
          </BreadCrumbList>
        ) : (
          <BreadCrumbList
            className="active"
            key={index}
            id={getDynamicBreadCrumbName(crumb.name)
              .trim()
              ?.replace(/\s+(?!\s*$)/g, '-')}
          >
            {getDynamicBreadCrumbName(crumb.name)}
          </BreadCrumbList>
        );
      })}
      {props.categoryDropdown && props.showCategoryDropdown && (
        <>
          <BreadCrumbList>
            <CategoryWrap>
              <a
                href="#"
                role="button"
                className="more-catgories"
                onClick={handleCategory}
              >
                {props.moreDropdownText ?? 'More Categories'}
              </a>

              {dropdownActive && (
                <CategoriesDropdown ref={dialogContentRef}>
                  {props.categoryDropdown?.subCategory?.length > 0 && (
                    <CategoryList id="subCategoryList">
                      {!props.skipSubCategoryTitle && (
                        <li className="dropdwon-header">
                          {props?.categoryTitle?.subCategory ||
                            'Sub-Categories'}
                        </li>
                      )}
                      {getCategoryDropdown(props.categoryDropdown?.subCategory)}
                    </CategoryList>
                  )}
                  {props.categoryDropdown?.relatedCategory &&
                    props.categoryDropdown?.relatedCategory.length > 0 && (
                      <CategoryList id="relatedCategoryList">
                        {!props.skipSubCategoryTitle && (
                          <li className="dropdwon-header">
                            {props?.categoryTitle?.relatedCategory ||
                              'Related Categories'}
                          </li>
                        )}
                        {getCategoryDropdown(
                          props.categoryDropdown?.relatedCategory
                        )}
                      </CategoryList>
                    )}
                </CategoriesDropdown>
              )}
            </CategoryWrap>
          </BreadCrumbList>
        </>
      )}
    </BreadCrumbWrap>
  );
};

export default Breadcrumb;
