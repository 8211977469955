import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const SocialLoginBtn = styled.div<{
  isLogin?: boolean;
  isGoogle?: boolean;
  showOnlyGoogle?: boolean;
  isSSOExp?: boolean;
}>`
  padding: ${({ isLogin }) => (isLogin ? ' 0 0 1.5rem' : ' 0 1.5rem 1.5rem')};
  margin: ${({ isLogin }) => (isLogin ? '0 -1.5rem;' : '0')};
  ${({ isGoogle, showOnlyGoogle }) =>
    isGoogle && !showOnlyGoogle
      ? Mixin.GridLayout({
          columnGap: '2.5rem',
          rowGap: '2.5rem',
          mobDimension: 'repeat(2 ,1fr)',
        })
      : ''};

  ${device.mobileTabSm} {
    ${({ isGoogle }) =>
      isGoogle
        ? Mixin.GridLayout({
            rowGap: '2rem',
            gridDimension: ' repeat(auto-fill, minmax(20rem, 1fr))',
          })
        : ''};
  }
  ${({ theme: { LoginStyle }, isSSOExp }) =>
    LoginStyle &&
    css`
      color: ${LoginStyle.Colors.SocialLoginBtn};
      .social-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 1.67;
        ${device.mobileTab} {
          padding: 0.7rem 1.6rem;
          color: ${isSSOExp
            ? `${LoginStyle.Colors.socialLoginBtnVariant}`
            : 'inherit'};
          border-color: ${isSSOExp
            ? `${LoginStyle.BdrColors.socialLoginBtnVariant}`
            : 'inherit'};
          padding: ${isSSOExp && '0.45rem 1.6rem 0.45rem 1.6rem'};
          font-size: ${isSSOExp && '1.8rem'};
        }
      }
      img {
        margin-right: 1.5rem;
        vertical-align: middle;
        ${device.mobileSm} {
          margin-right: 1rem;
          height: 1.9rem;
        }
      }
    `}
  ${device.mobileLg} {
    margin: 0;
    padding: 0 0.8rem 1.5rem;
  }
  ${({ isSSOExp }) =>
    isSSOExp &&
    css`
      ${device.mobileLg} {
        display: flex;
        flex-direction: column;
        gap: 2.8rem;
        padding: 0;
      }
      ${device.mobileSm} {
        img {
          height: auto;
          max-height: 2.5rem;
          margin-right: 1.4rem;
        }
      }
    `}
`;
