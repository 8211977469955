import { CancelIcon, ToasterIcon, Wrapper } from './styled';

interface Props {
  icon?: string;
  description: string;
  status?: string;
  cancelIcon?: boolean;
  cancelToaster?: (_e: React.SyntheticEvent) => void;
  id?: string;
  role?: string;
  noIconWrap?: boolean;
}

const Toaster = ({
  icon,
  description,
  status,
  cancelIcon,
  cancelToaster,
  id,
  role,
  noIconWrap,
}: Props) => {
  return (
    <Wrapper status={status} id={id} role={role} noIconWrap={noIconWrap}>
      {icon && (
        <ToasterIcon status={status} className={`fa ${icon}`}></ToasterIcon>
      )}
      {description && (
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      )}
      {cancelIcon && cancelToaster && (
        <CancelIcon
          aria-label="Close"
          onClick={(e) => cancelToaster(e)}
        ></CancelIcon>
      )}
    </Wrapper>
  );
};

export default Toaster;
