import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';
const type: { [key: string]: any } = {
  success: css`
    ${({ theme: { ToasterSectionStyle } }) =>
      ToasterSectionStyle &&
      css`
        background-color: ${ToasterSectionStyle.BgColors.success};
        border: 0.1rem solid ${ToasterSectionStyle.BdrColors.success};
        color: ${ToasterSectionStyle.Colors.success};
        padding: 3.2rem 4rem 3.2rem 1.6rem;
      `}
  `,
  alert: css`
    ${({ theme: { ToasterSectionStyle } }) =>
      ToasterSectionStyle &&
      css`
        background-color: ${ToasterSectionStyle.BgColors.bgalert};
        border: 0.1rem solid ${ToasterSectionStyle.BdrColors.bdralert};
        color: ${ToasterSectionStyle.Colors.coloralert};
        padding: 1.6rem;
      `}
  `,
  alertLight: css`
    ${({ theme: { ToasterSectionStyle } }) =>
      ToasterSectionStyle &&
      css`
        background-color: ${ToasterSectionStyle.BgColors.bgalert};
        border: 0.1rem solid ${ToasterSectionStyle.BdrColors.bdralert};
        color: ${ToasterSectionStyle.Colors.coloralert1};
        padding: 1.6rem;
        font-weight: 600;
      `}
  `,
  info: css`
    ${({ theme: { ToasterSectionStyle } }) =>
      ToasterSectionStyle &&
      css`
        background-color: ${ToasterSectionStyle.BgColors.info};
        border: 0.1rem solid ${ToasterSectionStyle.BdrColors.info};
        color: ${ToasterSectionStyle.Colors.info};
        padding: 1.6rem;
      `}
  `,
  tips: css`
    padding: 1.6rem 0.8rem;
    margin-top: 2.4rem;
    align-items: flex-start;
    ${({ theme: { ToasterSectionStyle } }) =>
      ToasterSectionStyle &&
      css`
        background-color: ${ToasterSectionStyle.BgColors.tips};
        border: 0.1rem solid ${ToasterSectionStyle.BdrColors.tips};
        color: ${ToasterSectionStyle.Colors.tips};
      `}
  `,
};
export const Wrapper = styled.div<{ status?: string; noIconWrap?: boolean }>`
  margin: 0 0 1.6rem;
  border-radius: 0.4rem;
  line-height: 1.5;
  position: relative;
  ${Mixin.VerticalCenter({ align: 'flex-start' })};
  ${({ status }) => (status && type[status]) || type['info']};
  padding-right: ${({ noIconWrap }) => noIconWrap && '5.6rem'};
`;

const icontype: { [key: string]: any } = {
  success: css`
    ${({ theme: { ToasterSectionStyle } }) =>
      ToasterSectionStyle &&
      css`
        color: ${ToasterSectionStyle.Colors.success};
      `}
  `,
  alert: css`
    ${({ theme: { ToasterSectionStyle } }) =>
      ToasterSectionStyle &&
      css`
        color: ${ToasterSectionStyle.Colors.coloralert};
      `}
  `,
  alertLight: css`
    ${({ theme: { ToasterSectionStyle } }) =>
      ToasterSectionStyle &&
      css`
        color: ${ToasterSectionStyle.Colors.coloralert1};
      `}
  `,
  info: css`
    ${({ theme: { ToasterSectionStyle } }) =>
      ToasterSectionStyle &&
      css`
        color: ${ToasterSectionStyle.Colors.info};
      `}
  `,
};

export const ToasterIcon = styled.i<{ status?: string }>`
  padding-right: 1.2rem;
  text-align: center;
  line-height: 1.5;
  ${({ status }) => (status && icontype[status]) || icontype['info']};
`;
export const CancelIcon = styled.i`
  width: 1.6rem;
  height: 1.6rem;
  ${Mixin.Position({ value: '0 0 auto auto' })};
  opacity: 0.5;
  margin: 2rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1.6rem auto no-repeat;
  ${device.smallDesktopView} {
    &:hover {
      opacity: 1;
    }
    &:focus {
      outline: none;
    }
  }
  ${device.mediumScreen} {
    cursor: pointer;
  }
`;
