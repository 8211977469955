import styled, { css } from 'styled-components';

export const BreadCrumbWrap = styled.ul`
  margin: 0 0 1.6rem;
  padding: 0.5rem 0 0.8rem;
  list-style: none;
`;

export const BreadCrumbList = styled.li`
  font-weight: 300;
  display: inline;
  line-height: 1.95rem;
  &.active {
    font-weight: 600;
  }

  & + li {
    &.active,
    &::before {
      content: '\/';
      padding: 0 0.7rem 0 0.4rem;
    }
  }

  ${({ theme: { BreadcrumbStyle } }) =>
    BreadcrumbStyle &&
    css`
      font-size: ${BreadcrumbStyle.FontSizes.breadcrumb};
    `}
  .more-catgories {
    &::after {
      content: '';
      display: inline-block;
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-left: 0.3em solid transparent;
      vertical-align: 0.2rem;
      margin-left: 0.4rem;
    }
  }
`;

export const CategoryWrap = styled.div`
  position: relative;
  display: inline-block;
`;

export const CategoriesDropdown = styled.div`
  position: absolute;
  padding: 0.8rem 0;
  border-radius: 0.4rem;
  min-width: max-content;
  z-index: 9;
  ${({ theme: { BreadcrumbStyle } }) =>
    BreadcrumbStyle &&
    css`
      background-color: ${BreadcrumbStyle.BgColors.categoriesDropdown};
      border: 0.1rem solid ${BreadcrumbStyle.BdrColors.categoriesDropdown};
      font-size: ${BreadcrumbStyle.FontSizes.dropdownHeader};
    `}
`;

export const CategoryList = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
  li {
    padding: 0.4rem 1rem;
    &.dropdwon-header {
      ${({ theme: { BreadcrumbStyle } }) =>
        BreadcrumbStyle &&
        css`
          color: ${BreadcrumbStyle.Colors.dropdownHeader};
        `}
    }
  }
`;
